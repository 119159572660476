import styled, { css } from "styled-components";
import {
  AlignmentHorizontalOption,
  ISpacing,
  TInputReadoutValue,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { alignmentOptionsToValue } from "../../../../../settingsPatterns/composeCss";
import { convertInputIdToValues } from "@ecp-boxes/shared/components/Input/StyledInput/StyledInput.methods";

export const StyledAddToCartWrapper = styled.div<{
  $alignment: AlignmentHorizontalOption;
  inputStyle?: TInputReadoutValue;
  padding?: ISpacing;
}>(({ $alignment, inputStyle, theme, padding }) => {
  return css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: ${alignmentOptionsToValue($alignment)};

    .product-module__content-container__add-to-cart-quantity {
      &__minus-button,
      &__plus-button {
        width: min-content;
      }
      &__quantity-input {
        text-align: center;
        ${inputStyle
          ? css`
              width: ${convertInputIdToValues(
                inputStyle,
                theme.globalObjects?.inputs
              ).width}%;
            `
          : ""}
        ${padding
          ? css`
              margin-right: ${padding.right}px;
              margin-left: ${padding.left}px;
            `
          : ""}
      }
    }
  `;
});
